import { User, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react'
import { auth, isLoggedIn } from '../../../firebase-config';
import CobraHeader from '../../client-ui/shared/CobraHeader';
import { Signin } from '../../locust/components/Signin';
import { LayoutFS } from '../../locust/composables/Layout';
import { getShoppingCart } from '../../service/shopping-cart';
import { Signup } from '../../locust/components/Signup';
import { ToastContainer, toast } from 'react-toastify';
import Register, { AuthConstants, DontSeeYourRegistrationEmail } from '../../locust/components/Register';
import { getCurrentUser, getUserEmail, getUserVerified } from '../../service/user-service';
import SpacerV from '../../locust/sections/SpacerV';
import { AuthLogo, AuthLogoLgCentered, FineprintRsp, SubtitleIIRsp, SubtitleRsp, TitleRspAlt } from '../../locust/components/Auth';
import { FACEBOOK_BLACK } from '../../assets/colors/Colors';
import X from '../../locust/composables/X';
import { trimSafe } from '../../locust/composables/Shared';


enum PageState {
    LOADING, 
    SIGN_UP,
    CHECK_YOUR_EMAIL,
    ALREADY_REGISTERED,
    DIFFERENT_ACCOUNT
}

const Content = {
    signup : {
        title : `Check Your Email`,
        subtitle : 'Seriously. We love you.',
        fineprint  : 'By using this website, you agree to the Terms of Service and Privacy Policy, including Cookie Use.',
        signUpBtn : 'Register',
        hasAccount : `Dont See your Registration Email?`,
    }
}

export default () => {

    const [pageState, setPageState] = React.useState( PageState.LOADING )
    const [username, setUsername] = React.useState("")
    const [usernameInput, setUsernameInput] = React.useState("")
    const [isRegistered, setIsRegistered ] = React.useState(false)
    const [fineprintColor, setFineprintColor ] = useState('silver')
    const [fineprintValue, setFineprintValue ] = useState(Content.signup.fineprint)
    
    useEffect(()=>{
        // if they are not signed in... then it should forward them to
        auth.onAuthStateChanged(()=>{
            if(!isLoggedIn()) navigate("/auth/sign-in")
            setUsername(getUserEmail())
            //if(getUserVerified()) setPageState(PageState.ALREADY_REGISTERED); else 
            setPageState(PageState.SIGN_UP)
        });
        
        

    },[])


    return (
        <>

            <LayoutFS min={350}>
                <CobraHeader type={'store'} shoppingCart={Object.keys(getShoppingCart()).length}/>
                
            
                    { pageState === PageState.SIGN_UP && 
                        <DontSeeYourRegistrationEmail subtitle={""} setUsername={setUsernameInput} onClick={()=>{
                            if(trimSafe(usernameInput)==="") return toast("Please input your email")
                            


    
                            const emailMatchesAccountEmail : boolean = username === usernameInput
                            if( !emailMatchesAccountEmail ) setPageState( PageState.DIFFERENT_ACCOUNT )
                            else sendEmailVerification( getCurrentUser() as User  ).then(async ()=>{
                                
                                setPageState( PageState.CHECK_YOUR_EMAIL )
            
                            })

                        }}/>
                    }
                    


                    { pageState === PageState.CHECK_YOUR_EMAIL && 
                        <>

                            <SpacerV h={AuthConstants.GUTTER}/>
                            <AuthLogoLgCentered/>
                            <SpacerV h={AuthConstants.GUTTER}/>
                        
                            <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
                            <SubtitleRsp c={FACEBOOK_BLACK}>Check your Email shortly</SubtitleRsp>
                            
                            <SpacerV h={AuthConstants.GUTTER}/>

                            

                            <SubtitleIIRsp c={FACEBOOK_BLACK} >{Content.signup.hasAccount}</SubtitleIIRsp>
                            <SpacerV h={AuthConstants.GUTTER/4}/>
                            <X style={{ height : 55}}>
                                <FineprintRsp w={360} c={fineprintColor}>{fineprintValue}</FineprintRsp>    
                            </X>
                    
                        </>
                    }
                    

                    { pageState === PageState.ALREADY_REGISTERED && 
                        <>

                            <SpacerV h={AuthConstants.GUTTER}/>
                            <AuthLogoLgCentered/>
                            <SpacerV h={AuthConstants.GUTTER}/>
                        
                            <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
                            <SubtitleRsp c={FACEBOOK_BLACK}>You're already registered</SubtitleRsp>
                            
                            <SpacerV h={AuthConstants.GUTTER}/>

                                <X>lets make sure everything was completed properly. </X>
                
                            {/* 
                            <SubtitleIIRsp c={FACEBOOK_BLACK} >{Content.signup.hasAccount}</SubtitleIIRsp>
                            <SpacerV h={AuthConstants.GUTTER/4}/>
                            <X style={{ height : 55}}>
                                <FineprintRsp w={360} c={fineprintColor}>{fineprintValue}</FineprintRsp>    
                            </X> */}
                    
                        </>
                    }


                    { pageState === PageState.DIFFERENT_ACCOUNT && 
                        <>

                            <SpacerV h={AuthConstants.GUTTER}/>
                            <AuthLogoLgCentered/>
                            <SpacerV h={AuthConstants.GUTTER}/>
                        
                            <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
                            <SubtitleRsp c={FACEBOOK_BLACK}>This is not the email you signed in with.</SubtitleRsp>
                            
                            <SpacerV h={AuthConstants.GUTTER}/>

                            <X>Do you want to use this email instead (or was this a typo?)</X>
                
                            {/* 
                            <SubtitleIIRsp c={FACEBOOK_BLACK} >{Content.signup.hasAccount}</SubtitleIIRsp>
                            <SpacerV h={AuthConstants.GUTTER/4}/>
                            <X style={{ height : 55}}>
                                <FineprintRsp w={360} c={fineprintColor}>{fineprintValue}</FineprintRsp>    
                            </X> */}
                    
                        </>
                    }

                






           
            </LayoutFS>
            <ToastContainer hideProgressBar autoClose={2000} position="bottom-right"/>
        
        </>
    )
}
